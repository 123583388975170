import "./App.css";
import React, { useState, useEffect, useRef, cloneElement } from "react";
import {
  userpassauth,
  UNOSQapp,
  CAapp,
  ESPORTSapp,
  EVENTSapp,
} from "./firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import exportFromJSON from "export-from-json";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

function convertValuesToStrings(obj) {
  const convertedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      convertedObj[key] = obj[key].toString();
    }
  }
  return convertedObj;
}

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  return (
    <>
      {!isSignedIn ? (
        <SignIn setIsSignedIn={setIsSignedIn} />
      ) : (
        <SignedIn setIsSignedIn={setIsSignedIn} />
      )}
    </>
  );
};

// console.log(registrationType);

const SignedIn = ({ setIsSignedIn }) => {
  const [registrationType, setRegistrationType] = useState("");
  const [dataType, setDataType] = useState("");
  const [visibleData, setVisibleData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [editDatabtn, setEditDatabtn] = useState("Edit Data");
  const [db, setDb] = useState();
  const [eventsList, setEventsList] = useState([]); // for events sports registered students list
  const [filters, setFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const sports = [
    "Athletics(M)",
    "Athletics(W)",
    "Badminton(M)",
    "Badminton(W)",
    "Basketball(M)",
    "Basketball(W)",
    "Chess(Mixed)",
    "Cricket(M)",
    "Football(M)",
    "Football(W)",
    // "Hockey(M)",
    "Kabaddi(M)",
    "Kabaddi(W)",
    "Lawn tennis(M)",
    "Lawn tennis(W)",
    "Mr. Udghosh(M)",
    "Powerlifting(M)",
    "Squash(M)",
    "Squash(W)",
    "Table Tennis(M)",
    "Table Tennis(W)",
    "Ultimate Frisbee",
    "Volleyball(M)",
    "Volleyball(W)",
    "Weight Lifting(M)",
    "Weight Lifting(W)",
    // "Taekwondo(Mix)",
    "Aquatics(M)",
    "Aquatics(W)",
    "KHO-KHO(M)",
    "KHO-KHO(W)",
  ];

  const handleSignOut = () => {
    signOut(userpassauth)
      .then(() => {
        // Sign-out successful.
        alert("You have been signed out successfully");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
    setIsSignedIn(false);
  };
  useEffect(() => {
    switch (dataType) {
      case "CA":
        setDb(getFirestore(CAapp));
        break;
      case "UNOSQ":
        setDb(getFirestore(UNOSQapp));
        break;
      case "ESPORTS":
        setDb(getFirestore(ESPORTSapp));
        break;
      case "EVENTS":
        setDb(getFirestore(EVENTSapp));
        break;
    }
  }, [dataType]);

  useEffect(() => {
    async function fetchData() {
      console.log("Registration type is assigned:", typeof registrationType);

      if (registrationType == "CASpecial") {
        console.log("Registration type is assigned:", registrationType);
        console.log("the data type is ", dataType);
        const querySnapshot = await getDocs(collection(db, "users"));
        console.log(querySnapshot);
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(Object.entries(data[0]));
        const sortedData = data.map((obj) =>
          Object.fromEntries(Object.entries(obj).sort())
        );
        console.log(Object.entries(data[0]));
        setFetchedData(sortedData);
        const fetchedColumns = Object.keys(sortedData[0] || {});
        setColumns(fetchedColumns);
        const initialFilters = {};
        fetchedColumns.forEach((column) => {
          initialFilters[column] = "";
        });
        setFilters(initialFilters);
        console.log(initialFilters);
      }
      

      else if (registrationType == "Users24") {
        const querySnapshot = await getDocs(collection(db, registrationType));
        const data = querySnapshot.docs.map((doc) => doc.data());
        // console.log(data);
        let sortedData = data.map((obj) =>
          Object.fromEntries(Object.entries(obj).sort())
        );

        // calculating no of player array
        let allPlayers = [];
        for (const i in sortedData) {
          let sportsno = { total: 0 };
          const sportsData = sortedData[i]["sports"];
          // code for preparing registerd players list
          // key is sports and value is details of team registered

          for (const [key, value] of Object.entries(sportsData)) {
            // storing team details
            let teamDet = {};
            for (const [key1, value1] of Object.entries(value)) {
              if (key1 != "team") {
                teamDet[key1] = value1;
              }
            }

            // storing team members
            value["team"].map((mem) => {
              let teamMem = {};
              // teamMem["Name"] = mem["player"];
              // teamMem["subevennt"] = mem["email"];
              if (mem.hasOwnProperty("player")) {
                teamMem["Name"] = mem["player"];
              }
              if (mem.hasOwnProperty("subEvent")) {
                teamMem["SubEvent"] = mem["subEvent"];
              }
              if (mem.hasOwnProperty("number")) {
                teamMem["Number"] = mem["number"];
              }
              if (mem.hasOwnProperty("gender")) {
                teamMem["Gender"] = mem["gender"];
              }
              for (const [key1, value1] of Object.entries(teamDet)) {
                teamMem[key1] = value1;
              }
              teamMem["Sport"] = key;
              for (const [key1, value1] of Object.entries(sortedData[i])) {
                // teamMem[key1] = value1;
                if(key1!="sports"){
                  teamMem[key1] = value1;
                }
              }
              console.log(teamMem);
              allPlayers.push(teamMem);
            });
          }

          //
          let totalsports = 0;
          for (const sport of sports) {
            sportsno[sport] = 0;
          }
          // SportsNo is storing total and all sports initialised to 0
          // sportsdata is new var with sports data

          // iterating over all entries in sports data
          for (const sportKey in sportsData) {
            if (sportsData.hasOwnProperty(sportKey)) {
              console.log(sportsData);
              const sport = sportsData[sportKey];
              // console.log(team);
              const num = parseInt(sport.team.length);
              console.log(num);
              sportsno[sportKey] = num;
              totalsports += num;
            }
          }
          sportsno["total"] = totalsports;
          delete sortedData[i].sports;
          sortedData[i] = Object.assign({}, sortedData[i], sportsno);
        }
        setEventsList(allPlayers);
        setFetchedData(sortedData);
        console.log(sortedData);

        const fetchedColumns = Object.keys(sortedData[0] || {});
        setColumns(fetchedColumns);
        const initialFilters = {};
        fetchedColumns.forEach((column) => {
          initialFilters[column] = "";
        });
        setFilters(initialFilters);
        console.log(initialFilters);
      } else if (registrationType) {
        const querySnapshot = await getDocs(collection(db, registrationType));
        const data = querySnapshot.docs.map((doc) => doc.data());
        // console.log(data);
        const sortedData = data.map((obj) =>
          Object.fromEntries(Object.entries(obj).sort())
        );
        setFetchedData(sortedData);
        console.log(sortedData);

        const fetchedColumns = Object.keys(sortedData[0] || {});
        setColumns(fetchedColumns);
        const initialFilters = {};
        fetchedColumns.forEach((column) => {
          initialFilters[column] = "";
        });
        setFilters(initialFilters);
        console.log(initialFilters);
      }
    }
    fetchData();
  }, [registrationType]);

  const handleFilterChange = (event, column) => {
    const newFilters = {
      ...filters,
      [column]: event.target.value.toUpperCase(),
    };
    setFilters(newFilters);
  };

  const handleDataTypeChange = (e) => {
    const selectedValue = e.target.value;
    setDataType(selectedValue);
    switch (selectedValue) {
      case "CA":
        setVisibleData(["CASpecial"]);
        break;
      case "UNOSQ":
        setVisibleData(["individual registration", "Contingent registration"]);
        break;
      case "EVENTS":
        setVisibleData(["Users24"]);
        break;
      case "ESPORTS":
        setVisibleData(["People"]);
        break;
      default:
        setVisibleData([]);
    }
  };

  const downloadData = () => {
    if (registrationType) {
      const data = [];
      console.log(fetchedData);
      for (let i = 0; i < fetchedData.length; i++) {
        data.push(convertValuesToStrings(fetchedData[i]));
      }
      const fileName = registrationType;
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data, fileName, exportType });
    }
  };

  const DownloadPlayers = () => {
    const data = [];
    console.log(eventsList);
    for (let i = 0; i < eventsList.length; i++) {
      data.push(convertValuesToStrings(eventsList[i]));
    }
    const fileName = "Players";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  // DFS in every Sports of fetched DATA
  // const DownloadPlayers = () => {
  //   const JSONOUTPUT = [];
  //   const data = fetchedData;
  //   for (const i in data) {
  //     const sportsData = data[i]["sports"];

  const cellRef = useRef({});

  const updateData = async (rowIndex, columnKey) => {
    let file;

    if (registrationType === "individual registration") {
      file = fetchedData[rowIndex]["fullName"];
    } else {
      file = fetchedData[rowIndex]["schoolName"];
    }
    const docRef = doc(db, registrationType, file);

    await updateDoc(docRef, {
      [columnKey]: fetchedData[rowIndex][columnKey],
    });
  };

  const handleEdit = (rowIndex, columnKey) => {
    console.log("isEditable", isEditable);
    if (isEditable) {
      const cellElement = cellRef.current[rowIndex][columnKey];

      if (cellElement && cellElement instanceof HTMLElement) {
        let newValue;
        if (registrationType === "individual reistration") {
          newValue = prompt(
            `Enter the new value of ${columnKey} for ${fetchedData[rowIndex]["fullName"]}:`
          );
        } else {
          newValue = prompt(
            `Enter the new value of ${columnKey} for ${fetchedData[rowIndex]["schoolName"]}:`
          );
        }
        if (newValue !== null) {
          const newData = [...fetchedData];

          newData[rowIndex][columnKey] = newValue;

          setFetchedData(newData);

          updateData(rowIndex, columnKey);
        }
      }
    }
  };

  const handleDelete = (rowIndex) => {
    if (isEditable) {
      let reallydelete = prompt(
        "You have permission from heads to delete? (Y/N)"
      );
      if (reallydelete == "Y" || reallydelete == "y") {
        let data = fetchedData;
        if (rowIndex === 0) data = data.slice(1);
        else {
          let part1 = data.slice(0, rowIndex);
          let part2 = data.slice(rowIndex + 1, fetchedData.length);
          data = part1.concat(part2);
          alert(
            "The Data is not deleted from database contact web team for that"
          );
        }
        setFetchedData(data);
      }
    }
  };

  return (
    <div>
      <h1>Welcome! You are signed in.</h1>
      <div className="database-select-option">
        <select value={dataType} onChange={handleDataTypeChange}>
          <option value="">-Select-</option>
          {/* <option value="CA">CA</option>
          <option value="UNOSQ">UNOSQ</option> */}
          <option value="EVENTS">events</option>
          {/* <option value="ESPORTS">Esports</option> */}
        </select>

        {visibleData.length > 0 && (
          <div>
            <select
              value={registrationType}
              onChange={(e) => {
                setRegistrationType(e.target.value);
                setIsEditable(false);
                setEditDatabtn("Edit Data");
              }}
            >
              <option value="">-Select-</option>
              {visibleData.map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </select>
          </div>
        )}

        <button onClick={handleSignOut}>Sign Out</button>
      </div>

      {fetchedData.length > 0 && (
        <div>
          <h2>Fetched Data:</h2>
          <button onClick={downloadData}>Download Data as CSV</button>
          {
            // code for downloading sports data also
            registrationType === "Users24" && (
              <button onClick={DownloadPlayers}>
                Download players Data as CSV
              </button>
            )
          }
          <button
            onClick={() => {
              if (isEditable) {
                setIsEditable(false);
                setEditDatabtn("Edit Data");
              } else {
                setIsEditable(true);
                setEditDatabtn("Stop Editing");
              }
            }}
          >
            {editDatabtn}
          </button>
          <h3
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            Showing {fetchedData.length} entries
          </h3>
          <table className="data-table">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>
                    {column}{" "}
                    <input
                      key={column}
                      type="text"
                      value={filters[column]}
                      onChange={(e) => handleFilterChange(e, column)}
                      placeholder={`Filter by ${column}...`}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            {/* <tbody>
              {fetchedData.map((data, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(data).map((columnKey) => (
                    <td
                      key={columnKey}
                      ref={(element) => {
                        if (!cellRef.current[rowIndex]) {
                          cellRef.current[rowIndex] = {};
                        }
                        cellRef.current[rowIndex][columnKey] = element;
                      }}
                      style={{
                        cursor: isEditable ? "pointer" : "default",
                      }}
                    >
                      <div
                        onClick={() => handleEdit(rowIndex, columnKey)}
                      >
                        {data[columnKey]}
                      </div>
                    </td>
                  ))}
                  {isEditable && (
                    <button onClick={() => { handleDelete(rowIndex) }} className="minus-Sign" style={{
                    }}>
                      -
                    </button>
                  )}
                </tr>
              ))}
            </tbody> */}
            <tbody>
              {fetchedData.map((data, rowIndex) => {
                const shouldDisplayRow = Object.keys(data).every(
                  (columnKey) => {
                    const filterValue = filters[columnKey] || "";
                    return (
                      filterValue === "" ||
                      data[columnKey]
                        .toString()
                        .toUpperCase()
                        .includes(filterValue)
                    );
                  }
                );

                if (!shouldDisplayRow) {
                  return null;
                }

                return (
                  <tr key={rowIndex}>
                    {Object.keys(data).map((columnKey) => {
                      const filterValue = filters[columnKey] || "";
                      const cellValue = data[columnKey];
                      const shouldDisplayCell =
                        filterValue === "" ||
                        cellValue
                          .toString()
                          .toUpperCase()
                          .includes(filterValue);

                      return (
                        <td
                          key={columnKey}
                          ref={(element) => {
                            if (!cellRef.current[rowIndex]) {
                              cellRef.current[rowIndex] = {};
                            }
                            cellRef.current[rowIndex][columnKey] = element;
                          }}
                          style={{ display: shouldDisplayCell ? "" : "none" }}
                        >
                          <div onClick={() => handleEdit(rowIndex, columnKey)}>
                            {cellValue}
                          </div>
                        </td>
                      );
                    })}
                    {isEditable && (
                      <td>
                        <button
                          onClick={() => handleDelete(rowIndex)}
                          className="minus-Sign"
                        >
                          -
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const SignIn = ({ setIsSignedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = () => {
    signInWithEmailAndPassword(userpassauth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        setEmail("");
        setPassword("");
        setIsSignedIn(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  return (
    <div>
      <h1>Please Sign In</h1>
      <input
        type="text"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        wa
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSignIn}>Sign In</button>
    </div>
  );
};

export default App;
