// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const CAConfig = {
  apiKey: "AIzaSyBmH0jLElfZfmquzDwzdv6u3DwKbBxPf0M",
  authDomain: "ca-project-2023-268f1.firebaseapp.com",
  projectId: "ca-project-2023-268f1",
  storageBucket: "ca-project-2023-268f1.appspot.com",
  messagingSenderId: "174144672723",
  appId: "1:174144672723:web:124d6d9a6000b0b4985526",
  measurementId: "G-BZTYM4KKYT"
};
export const UNOSQConfig = {
  apiKey: "AIzaSyAPJyLwFTSVaBj9H3JyB81EdJaSE4hjluM",
  authDomain: "unosq-23-1.firebaseapp.com",
  databaseURL: "https://unosq-23-1-default-rtdb.firebaseio.com",
  projectId: "unosq-23-1",
  storageBucket: "unosq-23-1.appspot.com",
  messagingSenderId: "842602193414",
  appId: "1:842602193414:web:5a67f90ba1cd1e041bd987",
  measurementId: "G-4GZNPN14WH"
};
export const ESPORTSConfig = {
  apiKey: "AIzaSyBFWymv2K-xX10EBqRUoJxBclI5NwtzeL4",
  authDomain: "udghosh-admin-portal.firebaseapp.com",
  projectId: "udghosh-admin-portal",
  storageBucket: "udghosh-admin-portal.appspot.com",
  messagingSenderId: "269800883084",
  appId: "1:269800883084:web:f7ffd06216ab759bee4ca9"
};
export const EVENTSConfig = {
  apiKey: "AIzaSyBfKlmmlZYyeTKNaDTzqoYRfmXKe35_uMg",
  authDomain: "competitions-24.firebaseapp.com",
  projectId: "competitions-24",
  storageBucket: "competitions-24.appspot.com",
  messagingSenderId: "499383991962",
  appId: "1:499383991962:web:c681373b1c8baccdeaa923",
  measurementId: "G-TL3966ZFL0"
};
export const AUTHConfig = {
  apiKey: "AIzaSyByWG2bss4TEZjBkqXuEIPaTSqzVWiCCxo",
  authDomain: "adminportal-d8846.firebaseapp.com",
  projectId: "adminportal-d8846",
  storageBucket: "adminportal-d8846.appspot.com",
  messagingSenderId: "107121058806",
  appId: "1:107121058806:web:b52e94bf6e14afcb251dd7",
  measurementId: "G-TD0D6Z0TWQ"
};



// Initialize Firebase
const app = initializeApp(AUTHConfig);
export const CAapp = initializeApp(CAConfig, "second");
export const UNOSQapp = initializeApp(UNOSQConfig, "third");
export const ESPORTSapp =initializeApp(ESPORTSConfig, "forth");
export const EVENTSapp = initializeApp(EVENTSConfig, "fifth");
export const userpassdb = getFirestore(app);
export const userpassauth = getAuth(app);




